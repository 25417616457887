import React, { createContext, useState, useContext, useEffect } from 'react';

const DialogContext = createContext();

export const DialogProvider = ({ children }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);

  const [position, setPosition] = useState({ x: 0, y: 0 });

  const [propsData, setPropsData] = useState(null);

  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => {
    setIsDialogOpen(false);
    setIsMinimized(false);
    localStorage.removeItem('invoiceDtls');
    localStorage.removeItem('isConverted');
    setFormData(null);
    setPropsData(null);
  };
  const minimizeDialog = () => setIsMinimized(!isMinimized);
  const maximizeDialog = () => setIsMinimized(false);
  const updatePropsData = (data) => {
    setPropsData(data);
  };

  const [formData, setFormData] = useState(null);

  const updateFormData = (newData) => {
    setFormData((prevData) => ({ ...prevData, ...newData }));
  };

  useEffect(() => {
    // Retrieve the saved position from localStorage
    const savedPosition = localStorage.getItem('dialogPosition');
    if (savedPosition) {
      setPosition(JSON.parse(savedPosition));
    }
  }, []);

  return (
    <DialogContext.Provider
      value={{
        position,
        isDialogOpen,
        isMinimized,
        openDialog,
        setPosition,
        closeDialog,
        minimizeDialog,
        maximizeDialog,
        updatePropsData,
        propsData,
        formData,
        updateFormData,
      }}
    >
      {children}
    </DialogContext.Provider>
  );
};

export const useDialog = () => useContext(DialogContext);
